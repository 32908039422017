
import {Sponsor} from './Sponsor';
import Generico from './Generico';

const AprenderConectado = () => {

  return (
    <div className="container contOption">

    <div className="card mb-4">
    <div className="card-body">
      <h2 className="card-title">Equipos Aprender Conectados</h2>
      <div className="row">
          <div className="col-12 mt-3">

          <div className='row'>
                <div className='col-1 mt-2 d-flex justify-content-end'>
                    <span className="material-symbols-outlined">
                      error
                    </span>
                </div>
                <div className='col-10'>
                   <p className='text-warning'> IMPORTANTE! Para realizar el desbloqueo debés prender el equipo como se muestra en el video, 
                      ya que utilizaremos los numeros que se observan en la pantalla</p>
               </div>

          </div>

          </div>
          <div className="col-12 d-flex justify-content-center contVideo">
              <iframe 
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/n4hBZHV9Smo?si=wVfEyZhopq83CNPq" 
              title="YouTube video player" 
              className='video'
              allow="accelerometer;
               autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              >

                </iframe>
          </div>

      </div>
    </div>
    </div>

     
     <div id='contSponsor' >
        <Sponsor huesped="AprenderConectados"/>
     </div>
{
     <div id='contGenerico' className='d-none'>
      <Generico/>
     </div>
}
    </div>


  );
};

export default AprenderConectado;